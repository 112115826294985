import Cookies from "js-cookie";
export interface Account {
    customerId: number
}

const DAYS_TO_EXPIRE = 1

export const setAccount = (account: Account) => {
  Cookies.set("mews-account", JSON.stringify(account), { expires: DAYS_TO_EXPIRE });
}

export const getCustomerId = (): number | null => {
  const account = Cookies.get("mews-account")
  if (account) {
    return (JSON.parse(account) as Account).customerId
  } else  {
    return null
  }
}