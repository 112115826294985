import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Provider } from 'react-redux';
import { store } from './app/store';
import { ProSidebarProvider } from 'react-pro-sidebar';
import PrivateRoute from "./Components/PrivateRoute";

const LoginPage = lazy(() => import('./LoginPage/LoginPage'));
const UnlockPage = lazy(() => import('./UnlockPage/UnlockPage'));
const MainPage = lazy(() => import('./MainPage/Mainpage'));
const CompilsReceived = lazy(() => import('./CompilsReceived/CompilsReceived'));
const MyCompils = lazy(() => import('./MyCompils/MyCompils'));
const ViewCompilation = lazy(() => import('./ViewCompilation/ViewCompilation'));

function App() {
    return (
        <Provider store={store}>
            <ProSidebarProvider>
                <Router>
                    <div className="App">
                        <Suspense fallback={<div>Loading...</div>}>
                        <Routes>
                                {/* Public routes */}
                                <Route path="/login" element={<LoginPage />} />
                                <Route path="/unlock" element={<UnlockPage />} />

                                {/* Private routes */}
                                <Route path="/main-page" element={<PrivateRoute><MainPage /></PrivateRoute>} />
                                <Route path="/my-compils" element={<PrivateRoute><MyCompils /></PrivateRoute>} />
                                <Route path="/compils-received" element={<PrivateRoute><CompilsReceived /></PrivateRoute>} />
                                <Route path="/view-compilation" element={<PrivateRoute><ViewCompilation /></PrivateRoute>} />

                                {/* Default route */}
                                <Route path="/" element={<Navigate replace to="/main-page" />} />
                        </Routes>
                        </Suspense>
                    </div>
                </Router>
            </ProSidebarProvider>
        </Provider>
    );
}

export default App;
